export const baseURL = "https://bus.amwagtravel.com/modules/api/v1";

// Auth
export const LOGIN = "passangers/login";

export const REGISTER = "passangers/signup";
export const LOGOUT = "passangers/logout";
export const ChangePassword = "passangers/password";
export const CheckEmail = "passangers/check/email";
export const CheckMobile = "passangers/check/mobile";
export const CheckNational = "passangers/check/nid";
export const OTP = "passangers/otp/request";
export const ForgotPassword = "passangers/password/forgot";

// Locations
export const LOCATIONS = "locations";

// TripsList
export const TRIP = "triplist";

// Seats
export const CheckSeats = "tickets/seat";

// Tickts Pending
export const Pending = "tickets/pending";
export const PayNow = "tickets/paynow";
export const Pay = "tickets/PayTickets";
export const PayMobMethod = "tickets/paymob";
export const QNBMPGS = "tickets/qnbmpgs";
export const UPG = "tickets/upg";
export const payNew = "tickets/payNew";

// Payment
export const PaymentMethods = "tickets/PaymentMethods";

// User
export const PROFILE = "passangers/profile";
export const PROFILEINFO = "passangers/profileinfo";
export const TICKETSBOOKED = "passangers/tickets";
export const TICKETSDITAILS = "tickets/bookingid";

// Privacy
export const PRIVACY = "pages/privacypage";

// Terms
export const TERMS = "pages/termspage";

// About
export const ABOUT = "pages/aboutpage";

// Faq
export const FAQ = "pages/faqpage";

// Faq Questions
export const FAQQuestions = "pages/question";

// Contact Us
export const ContactUs = "inquiries/create";
